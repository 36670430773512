import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Chip, Typography, Hidden, CircularProgress } from '@material-ui/core';

import FakeExtract from './FakeExtract';
import Header from './Header';
import Filters from './Filters';
import DetailsDrawer from './DetailsDrawer';
import ResultNotFound from '../../components/ResultNotFound';
import LogoLoading from '../../components/LogoLoading';
import PrimaryModal from '../../components/PrimaryModal';
import { useValidationForUnformattedDate } from '../../hooks/useValidationForUnformattedDate';
import FloatButtonDownloadArchives from '../../components/FloatButtonDownloadArchives';

import { isMobile } from '../../utils/breakpoints';
import { isPix } from '../../utils/isPix';
import currencyFormatter from '../../utils/currencyFormatter';

import { handleDownloadFile } from '../../utils/handleDownloadFile';

import { ReactComponent as IconCashin } from '../../assets/cash-in.svg';
import { ReactComponent as IconCashOut } from '../../assets/cash-out.svg';
import { ReactComponent as PlusIcon } from '../../assets/plusCircleExtract.svg';
import { ReactComponent as NegativeIcon } from '../../assets/minus-circle.svg';
import { ReactComponent as OutflowIcon } from '../../assets/outflow-icon.svg';
import { ReactComponent as InputMoney } from '../../assets/input-money-icon.svg';
import { ReactComponent as ErrorIlustration } from '../../assets/error-ilustration.svg';

import styles from './styles.module.css';
import { actions } from './actions';
import { api } from '../../services/api';
import { handleSnackbarStack } from '../../utils/handleSnackbarStack';

function Extract() {
  const [transactions, setTransactions] = useState([]);

  const [errorOnTransactions, setErrorOnTransactions] = useState({
    hasError: false,
    message: '',
  });

  const [emptyList, setEmptyList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [logoLoading, setlogoLoading] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [details, setDetails] = useState({
    open: false,
    selected: {},
    loadingItem: '',
  });
  const [isFilteredFinalDate, setIsFilteredFinalDate] = useState(false);

  const [activeButton, setActiveButton] = useState(3);

  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [drawerFirstDate, setDrawerFirstDate] = useState('');
  const [drawerSecondDate, setDrawerSecondDate] = useState('');
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [loadingDownloadCsv, setLoadingDownloadCsv] = useState(false);
  const [loadingDownloadOfx, setLoadingDownloadOfx] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState({
    isOpen: false,
    title: '',
    text: '',
  });

  const validateInitialDateInfo = useValidationForUnformattedDate(initialDate);
  const validateFinalDateInfo = useValidationForUnformattedDate(finalDate);

  const COMPANY_CODE = sessionStorage.getItem('currentCompanyCode');

  const { accountSelected } = useSelector(state => state.account);

  const IS_POSITIVE_AMOUNT = 1;

  const documentName =
    initialDate && finalDate
      ? `Extrato-de-${moment(initialDate).format('DD/MM/YYYY')}-até-${moment(
          finalDate,
        ).format('DD/MM/YYYY')}`
      : `Extrato-de-${moment()
          .subtract(activeButton, 'days')
          .format('DD/MM/YYYY')}-até-${moment().format('DD/MM/YYYY')}`;

  useEffect(() => {
    const hasBothDates = initialDate && finalDate;
    const hasNoDate = !initialDate && !finalDate;

    if (hasNoDate && activeButton !== 0) {
      getExtract();
      return;
    }

    if (hasBothDates && !validateInitialDateInfo && !validateFinalDateInfo) {
      setActiveButton(0);
      getExtract();
      return;
    }

    if (hasNoDate) {
      handleClearFilters();
    }
  }, [initialDate, finalDate, activeButton]);

  useEffect(() => {
    if (!openDrawer && loading) {
      getExtract();
    }
  }, [openDrawer]);

  useEffect(() => {
    if (!isFilteredFinalDate) {
      getExtract();
    }
  }, [isFilteredFinalDate]);

  const getExtract = () => {
    if (!initialDate && !finalDate && !activeButton) return;

    setLoading(true);

    const requestOptions = {
      headers: {
        depositAccountId: accountSelected?.encodedKey,
        accountOrigin: accountSelected?.origin,
      },
      params: {
        lastDays: initialDate && finalDate ? 0 : activeButton,
        initialDate,
        finalDate,
      },
    };

    api
      .get(`/transactions/bankStatement/${COMPANY_CODE}`, requestOptions)
      .then(({ data }) => {
        setErrorOnTransactions({
          hasError: false,
          message: '',
        });
        setTransactions(data);
        setEmptyList(false);

        if (data.length === 0) {
          setEmptyList(true);
          setErrorOnTransactions({
            hasError: false,
            message: '',
          });
          setlogoLoading(false);
        }
      })

      .catch(({ response }) => {
        setErrorOnTransactions({
          hasError: true,
          message: response?.data?.errors[0].errorDetail,
        });
        setEmptyList(false);
      })
      .finally(() => {
        setLoading(false);
        setlogoLoading(false);
      });
  };

  const pdfDownloadExtract = () => {
    if (moment(finalDate).diff(initialDate, 'days') <= 90 || activeButton > 0) {
      setLoadingDownload(true);
      const requestOptions = {
        headers: {
          accept: 'application/json',
          branding: process.env.REACT_APP_BRAND_FOR_LOGIN_VALIDATION,
          depositAccountId: accountSelected?.encodedKey,
          accountOrigin: accountSelected?.origin,
        },
        params: {
          initialDate: initialDate && finalDate ? initialDate : '',
          finalDate: initialDate && finalDate ? finalDate : '',
          lastDays: initialDate && finalDate ? 0 : activeButton,
        },
        responseType: 'blob',
      };

      api
        .get(
          `/transactions/bankStatementReport/${COMPANY_CODE}`,
          requestOptions,
        )
        .then(({ data }) => {
          handleDownloadFile(data, documentName);
        })
        .finally(() => {
          setLoadingDownload(false);
        });
    } else {
      setIsErrorModalOpen({
        isOpen: true,
        title: 'Houve um erro ao fazer o download',
        text:
          'A diferença entre a data inicial e a data final deve ser menor ou igual a 90 dias (3 meses).',
      });
    }
  };

  const csvDownloadExtract = () => {
    if (moment(finalDate).diff(initialDate, 'days') <= 90 || activeButton > 0) {
      setLoadingDownloadCsv(true);
      const requestOptions = {
        headers: {
          accept: 'application/json',
          branding: process.env.REACT_APP_BRAND_FOR_LOGIN_VALIDATION,
          depositAccountId: accountSelected?.encodedKey,
          accountOrigin: accountSelected.origin,
        },
        params: {
          initialDate: initialDate && finalDate ? initialDate : '',
          finalDate: initialDate && finalDate ? finalDate : '',
          lastDays: initialDate && finalDate ? 0 : activeButton,
        },
        responseType: 'blob',
      };

      api
        .get(
          `/transactions/bankStatementReport/xls/${COMPANY_CODE}`,
          requestOptions,
        )
        .then(({ data }) => {
          handleDownloadFile(data, documentName, 'xls');
        })
        .finally(() => {
          setLoadingDownloadCsv(false);
        });
    } else {
      setIsErrorModalOpen({
        isOpen: true,
        title: 'Houve um erro ao fazer o download',
        text:
          'A diferença entre a data inicial e a data final deve ser menor ou igual a 90 dias (3 meses).',
      });
    }
  };

  const ofxDownloadExtract = () => {
    if (moment(finalDate).diff(initialDate, 'days') <= 90 || activeButton > 0) {
      setLoadingDownloadOfx(true);
      const requestOptions = {
        headers: {
          accept: 'application/json',
          depositAccountId: accountSelected?.encodedKey,
          accountOrigin: accountSelected.origin,
        },
        params: {
          initialDate: initialDate && finalDate ? initialDate : '',
          finalDate: initialDate && finalDate ? finalDate : '',
          lastDays: initialDate && finalDate ? 0 : activeButton,
        },
        responseType: 'blob',
      };

      api
        .get(
          `/transactions/bankStatementReport/ofx/${COMPANY_CODE}`,
          requestOptions,
        )
        .then(({ data }) => {
          handleDownloadFile(data, documentName, 'ofx');
        })
        .finally(() => {
          setLoadingDownloadOfx(false);
        });
    } else {
      setIsErrorModalOpen({
        isOpen: true,
        title: 'Houve um erro ao fazer o download',
        text:
          'A diferença entre a data inicial e a data final deve ser menor ou igual a 90 dias (3 meses).',
      });
    }
  };

  const onButtonFilterClicked = id => {
    if (id === activeButton) return;

    setActiveButton(id);
    setInitialDate('');
    setFinalDate('');
  };

  const onChangeInitialDate = value => {
    setInitialDate(
      moment(value).format('YYYY-MM-DD') !== 'Data inválida'
        ? moment(value).format('YYYY-MM-DD')
        : value,
    );
  };

  const onChangeFinalDate = value => {
    setFinalDate(
      moment(value).format('YYYY-MM-DD') !== 'Data inválida'
        ? moment(value).format('YYYY-MM-DD')
        : value,
    );
  };

  const handleClearFilters = () => {
    setDrawerFirstDate('');
    setDrawerSecondDate('');
    setInitialDate('');
    setFinalDate('');
    setActiveButton(3);
    getExtract();
  };

  const isTed = ({ code }) =>
    [1011, 2016].some(codeId => codeId === code);

  const handleOpenDetails = (transaction, id) => {
    if (isPix(transaction)) {
      setDetails({ selected: transaction, open: true });
      return;
    }

    if (isTed(transaction)) {
      const { documentNumber, history, transactionType, code } = transaction;

      const requestOptions = {
        headers: {
          depositAccountId: accountSelected?.encodedKey,
        },
      };

      const url = new URLSearchParams({
        documentNumber,
        history,
        event: transactionType,
        transferType: code,
      }).toString();

      setDetails({ ...details, loadingItem: id });

      api
        .get(`/transactions/bankStatement/ted/detail?${url}`, requestOptions)
        .then(response => {
          setDetails({
            selected: { ...transaction, ...response.data },
            open: true,
          });
        })
        .catch(_ => {
          setDetails({ ...details, loadingItem: '' });
          handleSnackbarStack().error(
            'Ocorreu um erro ao obter os detalhes desta transação. Por favor, tente novamente mais tarde.',
          );
        });
    }
  };

  const onCloseDetails = () => setDetails({ ...details, open: false });

  if (logoLoading) {
    return <LogoLoading />;
  }

  return (
    <div className={styles.container}>
      <Header
        pdfDownloadExtract={pdfDownloadExtract}
        csvDownloadExtract={csvDownloadExtract}
        ofxDownloadExtract={ofxDownloadExtract}
        loadingDownload={loadingDownload}
        loadingDownloadCsv={loadingDownloadCsv}
        loadingDownloadOfx={loadingDownloadOfx}
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        getExtract={getExtract}
        initialDate={initialDate}
        activeButton={activeButton}
        setInitialDate={setInitialDate}
        finalDate={finalDate}
        transactions={transactions}
        companyCode={COMPANY_CODE}
        setFinalDate={setFinalDate}
        drawerFirstDate={drawerFirstDate}
        drawerSecondDate={drawerSecondDate}
        setDrawerFirstDate={setDrawerFirstDate}
        setDrawerSecondDate={setDrawerSecondDate}
        setIsFilteredFinalDate={setIsFilteredFinalDate}
      />

      <Filters
        activeButton={activeButton}
        initialDate={initialDate}
        finalDate={finalDate}
        onButtonFilterClicked={onButtonFilterClicked}
        onChangeInitialDate={onChangeInitialDate}
        onChangeFinalDate={onChangeFinalDate}
        validateInitialDateInfo={validateInitialDateInfo}
        validateFinalDateInfo={validateFinalDateInfo}
      />

      <DetailsDrawer details={details} onClose={onCloseDetails} />

      {finalDate && isMobile && isFilteredFinalDate && (
        <Chip
          className={styles.chipItem}
          label={`${moment(initialDate).format('DD/MM/YYYY')} a ${moment(
            finalDate,
          ).format('DD/MM/YYYY')}`}
          onDelete={() => {
            setIsFilteredFinalDate(false);
            setInitialDate('');
            setFinalDate('');
            setDrawerFirstDate('');
            setDrawerSecondDate('');
            setActiveButton(3);
          }}
        />
      )}

      {loading ? (
        <FakeExtract />
      ) : (
        <>
          {emptyList && isMobile ? (
            <ResultNotFound
              text="Nenhum Resultado Encontrado"
              alternativeText="Verifique os dados pesquisados e tente novamente."
              buttonText="Limpar Filtros"
              style={styles.resultNotFound}
              onClick={handleClearFilters}
            />
          ) : (
            <div className={styles.itens}>
              {errorOnTransactions.hasError ? (
                <ResultNotFound
                  icon={<ErrorIlustration />}
                  text="Ops! Houve um erro ao buscar as transações"
                  alternativeText={errorOnTransactions.message}
                  buttonText="Limpar Filtros"
                  onClick={handleClearFilters}
                />
              ) : emptyList ? (
                <div className={styles.emptyListContainer}>
                  <Typography className={styles.emptyListContainerText}>
                    Não há registro de movimentações neste período.
                  </Typography>
                </div>
              ) : (
                transactions.map((item, dayIndex) => (
                  <div className={styles.extractItem}>
                    <div className={styles.extractItemHeader}>
                      <Typography className={styles.extractItemHeaderLabel}>
                        {moment(item.moveDate).format('DD/MM/YYYY')}
                      </Typography>
                      <Typography className={styles.extractItemHeaderLabel}>
                        {isMobile ? 'Saldo:' : 'Saldo no dia'}
                        <Typography
                          className={styles.extractItemHeaderLabelBlack}
                        >
                          {currencyFormatter(item.balanceAfter)}
                        </Typography>
                      </Typography>
                    </div>

                    {item.transactionsDay.map(
                      (transaction, transactionIndex) => (
                        <>
                          <div
                            className={`${styles.informations} ${
                              isTed(transaction) || isPix(transaction)
                                ? styles.hoverable
                                : ''
                            }`}
                            onClick={() =>
                              handleOpenDetails(
                                transaction,
                                `${dayIndex}-${transactionIndex}`,
                              )
                            }
                          >
                            <div className={styles.categoryContainer}>
                              <div>
                                {Math.sign(transaction.amount) ===
                                IS_POSITIVE_AMOUNT ? (
                                  <IconCashin
                                    className={styles.iconExtractTransactions}
                                  />
                                ) : (
                                  <IconCashOut
                                    className={styles.iconExtractTransactions}
                                  />
                                )}
                              </div>
                              <div className={styles.inOutContainer}>
                                {transaction.category === 'Saída'
                                  ? isMobile && (
                                      <OutflowIcon
                                        className={styles.inOutIcon}
                                      />
                                    )
                                  : isMobile && (
                                      <InputMoney
                                        className={styles.inOutIcon}
                                      />
                                    )}
                                <div className={styles.inOut}>
                                  <Typography
                                    className={styles.informationsTitle}
                                  >
                                    {transaction.category}
                                    <span className={styles.hourCategory}>
                                      {moment(transaction.timestamp).format(
                                        'LT',
                                      )}
                                    </span>
                                  </Typography>
                                  <Typography
                                    className={styles.informationsDescription}
                                  >
                                    {transaction.transactionChannel ===
                                    'migrations'
                                      ? transaction.notes
                                      : transaction.transactionType
                                      ? transaction.transactionType
                                      : 'Transação processada'}
                                  </Typography>
                                </div>
                              </div>
                            </div>

                            {details.loadingItem ===
                              `${dayIndex}-${transactionIndex}` && (
                              <CircularProgress
                                size={20}
                                color="primary"
                                className={styles.spinner}
                              />
                            )}

                            {Math.sign(transaction.amount) ===
                            IS_POSITIVE_AMOUNT ? (
                              <div className={styles.values}>
                                <Typography
                                  className={styles.informationsValueInput}
                                >
                                  {currencyFormatter(transaction.amount)}
                                  <PlusIcon className={styles.plusIcon} />
                                </Typography>
                              </div>
                            ) : (
                              <div className={styles.values}>
                                <Typography
                                  className={styles.informationsValueOutput}
                                >
                                  {isMobile
                                    ? currencyFormatter(transaction.amount)
                                    : currencyFormatter(
                                        transaction.amount,
                                      ).replace('-', '')}
                                  <NegativeIcon
                                    className={styles.negativeIcon}
                                  />
                                </Typography>
                              </div>
                            )}
                          </div>
                          <div className={styles.divider} />
                        </>
                      ),
                    )}
                  </div>
                ))
              )}
            </div>
          )}
        </>
      )}
      {transactions.length > 0 && (
        <Hidden smUp>
          <FloatButtonDownloadArchives
            startDate={initialDate}
            activeButton={activeButton}
            companyCode={COMPANY_CODE}
            actions={actions}
            getData={transactions}
            endDate={finalDate}
            pdfDownloadExtract={pdfDownloadExtract}
            csvDownloadExtract={csvDownloadExtract}
          />
        </Hidden>
      )}
      <PrimaryModal
        open={isErrorModalOpen?.isOpen}
        title={isErrorModalOpen?.title}
        text={isErrorModalOpen?.text}
        confirmButtonText="Entendi"
        onConfirmClicked={() =>
          setIsErrorModalOpen(prevState => ({ ...prevState, isOpen: false }))
        }
      />
    </div>
  );
}

export default Extract;
