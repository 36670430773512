import React from 'react';
import { ModalDef } from '@ebay/nice-modal-react';

import { SomapayScdPresentationModal } from './SomapayScdPresentationModal';
import IncomeModal from './RevenueModal';
import RevenueConfirmModal from './RevenueModal/RevenueConfirmModal';
import BeeValePopup from './BeeValePopup';
import RaPrizePopup from './RaPrizePopup';

export function HomeModals() {
  return (
    <>
      <ModalDef
        id="somapay-scd-presentation"
        component={SomapayScdPresentationModal}
      />
      <ModalDef id="revenue-modal" component={IncomeModal} />
      <ModalDef id="revenue-confirm-modal" component={RevenueConfirmModal} />
      <ModalDef id="bee-vale-popup" component={BeeValePopup} />
      <ModalDef id="ra-prize-popup" component={RaPrizePopup} />
    </>
  );
}
